//Permet d'ajouter et supprimer des véhicules des favoris d'un utilisateur
import toastify from "./_toastify";
export default function comparatorVehicle(id, element) {

    const displayCount = document.getElementById('comparator');
    const displayCountMobile = document.getElementById('comparatorMobile');

    return fetch('/ajax/comparator/toggle/' + id, {
        method: "POST",
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        })
    }).then((response) => {
        response.json().then((json) => {
            displayCount.innerHTML = json.count;
            displayCountMobile.innerHTML = json.count;

            if ('added' === json.action) {
                if(undefined !== element.closest('h3')) {
                    element.closest('h3').querySelector('.btn-comparator').classList.add('active');
                }
            } else if ('removed' === json.action) {
                if(undefined !== element.closest('h3')) {
                    element.closest('h3').querySelector('.btn-comparator').classList.remove('active');
                }
            }
            if (response.status !== 200) {
                toastify('Une erreur est survenue.', 'error');
            } else {
                toastify(json.content, 'success');
            }
        })
    });
};
