//Permet d'ajouter et supprimer des véhicules des favoris d'un utilisateur
import toastify from "./_toastify";
export default function favoriteVehicle(id, element) {

    const displayCount = document.getElementById('favorites');
    const displayCountMobile = document.getElementById('favoritesMobile');

    fetch('/ajax/favorite/toggle/' + id, {
        method: "POST",
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        })
    }).then((response) => {
        response.json().then((json) => {
            displayCount.innerHTML = json.count;
            displayCountMobile.innerHTML = json.count;
            if ('added' === json.action) {
                element.closest('h3').querySelector('.btn-favorite').classList.add('active');
                element.closest('h3').querySelector('.icon-favorite').classList.remove('icon-park-off');
                element.closest('h3').querySelector('.icon-favorite').classList.add('icon-park-on');
            } else if ('removed' === json.action) {
                element.closest('h3').querySelector('.icon-favorite').classList.remove('icon-park-on');
                element.closest('h3').querySelector('.icon-favorite').classList.add('icon-park-off');
                element.closest('h3').querySelector('.btn-favorite').classList.remove('active');

            }
            if (response.status !== 200) {
                toastify('Une erreur est survenue.', 'error');
            } else {
                toastify(json.content, 'success');
            }
        })
    })
};
