import Modal from 'bootstrap.native/dist/components/modal-native.esm.js';
import favoriteVehicle from './_favorite';
import comparatorVehicle from './_comparator';
import initModalFunding from "./_funding";

//import { addVehicleToComparator, deleteVehicleByIdInComparator } from '../comparator/comparator';
import arrayFromJsonCookie from '../components/arrayFromCookie'
// Lecture du "cookie"
const vehiclesInFavorite = arrayFromJsonCookie('favorites');

const initItemVehicleClick = () =>  {

  //click info financement;
  const vehiclesMonthly = document.querySelectorAll('.item-vehicle_monthly > .info');

  if (vehiclesMonthly.length) {
    Array.from(vehiclesMonthly, info => {
      info.addEventListener('click', e => {
        const showFundingModalEvent = new CustomEvent('showFundingModal', { detail: {vehicleId : e.target.dataset.vehicle } });
        document.dispatchEvent(showFundingModalEvent);
        e.preventDefault();
      });
    });
  }

  document.addEventListener('showFundingModal', e => {
    const vehicleId = e.detail.vehicleId;
    document.getElementById('modal-details').innerHTML = `<div class="flex-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>` ;
    document.getElementById('modal-legals').innerHTML = '' ;
    const modal = new Modal(document.getElementById('modal-funding'));
    modal.show();
    const options = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    }
    fetch('/ajax/funding-legal-notice/' + vehicleId, options).then((response) => {
      response.json().then((json) => {
        const modalDetails = document.getElementById('modal-details');
        const modalLegals = document.getElementById('modal-legals');
        modalDetails.innerHTML = json.content[0];
        modalLegals.innerHTML  = json.content[1];
      });
    });
  });


  const vehiclesFavorite = document.querySelectorAll('.item-vehicle_favorite');
  if (vehiclesFavorite.length) {
    Array.from(vehiclesFavorite, favorite => {
      const vehicleId = favorite.dataset.vehicleId;

      favorite.addEventListener('click', e => {
        const addFavoriteEvent = new CustomEvent('addFavorite', { detail: {vehicleId : vehicleId, target: e.target } });
        document.dispatchEvent(addFavoriteEvent);
        e.preventDefault();
      });
    });
  }

  document.addEventListener('addFavorite', e => {
    favoriteVehicle(e.detail.vehicleId, e.detail.target);
  });

  const vehiclesComparator = document.querySelectorAll('.item-vehicle_comparator');
  if (vehiclesComparator.length) {
    Array.from(vehiclesComparator, comparator => {
      const vehicleId = comparator.dataset.vehicleId;

      comparator.addEventListener('click', e => {
        const addComparatorEvent = new CustomEvent('addComparator', { detail: {vehicleId : vehicleId, target: e.target } });
        document.dispatchEvent(addComparatorEvent);
        e.preventDefault();
      });
    });
  }

  document.addEventListener('addComparator', e => {
    comparatorVehicle(e.detail.vehicleId, e.detail.target);
  });

};

window.addEventListener('DOMContentLoaded', () => {
  initItemVehicleClick();
})
