export default function arrayFromJsonCookie(cookieName)  {
  const cookieContent = Object.fromEntries(document.cookie.split(/; */).map(c => {
    const [ key, ...v ] = c.split('=');
    return [ key, decodeURIComponent(v.join('=')) ];
  }));

  let table = {};
  if(cookieContent[cookieName] != undefined) {
    table = JSON.parse(cookieContent[cookieName]);
  }

  return Object.values(table)
};
